import { GetterTree } from "vuex";
import { RootState } from "..";
import {
  Flow,
  FlowElement,
  Form,
  FormOpportunity,
  FormProtocol,
  State,
  FormFormulaMapping,
} from "./state";
import useCrypto from "@/composables/crypto";
import {
  FormOpportunitySearch,
  FormOpportunityProtocolSearch,
  FormMappingSearch,
} from "../../views/search.interface";
import { JobTypeEnum, ProtocolTypeEnum } from "@gid/models";

const { isValidUUID } = useCrypto();

// Getters types
export type Getters = {
  formByUUID(state: State): (uuid: string) => Form;
  forms(state: State): Form[];
  sortedFormFlows(state: State): (form: Form) => Flow[];
  formFlowByUUID(state: State): (form: Form, flowUUID: string) => Flow | null;
  formFlowByElementUUID(
    state: State
  ): (form: Form, elementUUID: string) => Flow | null;
  currentFormFlow(state: State): (form: Form) => Flow | null;
  currentFormFlowElement(state: State): (form: Form) => FlowElement | null;
  formOpportunities(
    state: State
  ): (form: Form, criteria?: FormOpportunitySearch | null) => FormOpportunity[];
  formProtocols(
    state: State
  ): (
    form: Form,
    criteria?: FormOpportunityProtocolSearch | null
  ) => FormProtocol[];
  formFormulaMapping(
    state: State
  ): (
    form: Form,
    opportunityId: string,
    criteria?: FormMappingSearch | null
  ) => FormFormulaMapping[];
  elements(state: State): (form: Form) => FlowElement[];
  elementsCount(state: State): (form: Form) => number;
  elementByUUID(
    state: State
  ): (form: Form, elementUUID: string) => FlowElement | null;
  elementByName(
    state: State
  ): (form: Form, elementName: string) => FlowElement | null;
  currentMappingOpportunities(
    state: State
  ): (form: Form) => { value: string; label: string; key: string }[];
  defaultMappingNameGenerated(
    state: State
  ): (form: Form) => { name: string; nameGenerated: string | null }[];
  opportunityName(
    state: State
  ): (form: Form, opportunityId: string) => string | null;
  hasExistingFormOpportunityProtocol(
    state: State
  ): (
    form: Form,
    opportunityId: string,
    jobType: JobTypeEnum,
    protocolType: ProtocolTypeEnum
  ) => boolean;
};

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  formByUUID: (state) => {
    return (uuid: string) => state[uuid];
  },
  forms: (state) => {
    return Object.keys(state)
      .filter((x) => isValidUUID(x))
      .map((x) => state[x]);
  },
  sortedFormFlows: (state) => {
    return (form: Form) =>
      form.flows.sort((a: Flow, b: Flow) => {
        return Number(a.step) - Number(b.step);
      });
  },
  formFlowByUUID: (state) => {
    return (form: Form, flowUUID: string) =>
      Array.from(form.flows || []).find((x: Flow) => x.id == flowUUID) || null;
  },
  formFlowByElementUUID: (state) => {
    return (form: Form, elementUUID: string) =>
      Array.from(form.flows || []).find((x: Flow) =>
        x.elements.some((e: FlowElement) => e.id == elementUUID)
      ) || null;
  },
  currentFormFlow: (state) => {
    return (form: Form) =>
      form.currentFlowUUID
        ? Array.from(form.flows || []).find(
            (x: Flow) => x.id == form.currentFlowUUID
          ) || null
        : null;
  },
  currentFormFlowElement: (state) => {
    return (form: Form) =>
      form.currentElementUUID
        ? Array.from(form.flows || [])
            .flatMap((x) => x.elements)
            .find((x) => x.id === form.currentElementUUID) || null
        : null;
  },
  formOpportunities: (state) => {
    return (form: Form, criteria?: FormOpportunitySearch | null) => {
      if (!criteria) {
        return form.opportunities;
      }
      return form.opportunities?.filter(
        (fo) =>
          (criteria.formOpportunity.opportunityId || []).every((c) =>
            c.some((v) =>
              fo.opportunityId
                .toLowerCase()
                .trim()
                .includes(v.toLowerCase().trim())
            )
          ) &&
          (criteria.formOpportunity.name || []).every((c) =>
            c.some((v) =>
              fo.name.toLowerCase().trim().includes(v.toLowerCase().trim())
            )
          )
      );
    };
  },
  defaultMappingNameGenerated: (state) => {
    return (form: Form) => {
      return form.mapping.default.entries.map((e) => ({
        name: e.name,
        nameGenerated: e.nameGenerated,
      }));
    };
  },
  formProtocols: (state) => {
    return (form: Form, criteria?: FormOpportunityProtocolSearch | null) => {
      if (!criteria) {
        return Object.values(form.protocol || {}).flat();
      }
      return form.opportunities
        ?.filter(
          (fo) =>
            (criteria.formOpportunityProtocol.opportunityId || []).every((c) =>
              c.some((v) =>
                fo.opportunityId
                  .toLowerCase()
                  .trim()
                  .includes(v.toLowerCase().trim())
              )
            ) &&
            (criteria.formOpportunityProtocol.opportunityName || []).every(
              (c) =>
                c.some((v) =>
                  fo.name.toLowerCase().trim().includes(v.toLowerCase().trim())
                )
            )
        )
        .filter((fo) => Object.keys(form.protocol).includes(fo.opportunityId))
        .map((fo) => form.protocol[fo.opportunityId])
        .flat()
        .filter(
          (fp) =>
            (criteria.formOpportunityProtocol.jobType || []).every((c) =>
              c.some((v) =>
                fp.jobType.toLowerCase().trim().includes(v.toLowerCase().trim())
              )
            ) &&
            (criteria.formOpportunityProtocol.protocolType || []).every((c) =>
              c.some((v) =>
                fp.protocolType
                  .toLowerCase()
                  .trim()
                  .includes(v.toLowerCase().trim())
              )
            ) &&
            (criteria.formOpportunityProtocol.template || []).every((c) =>
              c.some((v) =>
                fp.template
                  .toLowerCase()
                  .trim()
                  .includes(v.toLowerCase().trim())
              )
            )
        );
    };
  },
  formFormulaMapping: (state) => {
    return (
      form: Form,
      opportunityId: string,
      criteria?: FormMappingSearch | null
    ) => {
      if (!criteria) {
        return form.mapping[opportunityId].entries;
      }
      return form.mapping[opportunityId].entries.filter(
        (e) =>
          (criteria.formMapping.name || []).every((c) =>
            c.some((v) =>
              e.name.toLowerCase().trim().includes(v.toLowerCase().trim())
            )
          ) &&
          (criteria.formMapping.servicesFormula || []).every((c) =>
            c.some((v) =>
              e.servicesFormula
                ?.toLowerCase()
                .trim()
                .includes(v.toLowerCase().trim())
            )
          ) &&
          (criteria.formMapping.productsFormula || []).every((c) =>
            c.some((v) =>
              e.productsFormula
                ?.toLowerCase()
                .trim()
                .includes(v.toLowerCase().trim())
            )
          )
      );
    };
  },
  elements: (state) => {
    return (form: Form) =>
      Array.from(form.flows || []).flatMap((x) => x.elements) || [];
  },
  elementsCount: (state) => {
    return (form: Form) =>
      (Array.from(form.flows || []).flatMap((x) => x.elements) || []).length;
  },
  elementByUUID: (state) => {
    return (form: Form, elementUUID: string) =>
      Array.from(form.flows || [])
        .flatMap((x) => x.elements)
        .find((x) => x.id === elementUUID) || null;
  },
  elementByName: (state) => {
    return (form: Form, elementName: string) =>
      Array.from(form.flows || [])
        .flatMap((x) => x.elements)
        .find((x) => x.name === elementName) || null;
  },
  currentMappingOpportunities: (state) => {
    return (form: Form) =>
      Object.keys(form.mapping).map((x) => {
        return {
          key: x,
          value: x,
          label:
            form.opportunities.find((fo) => fo.opportunityId == x)?.name || x,
        };
      });
  },
  opportunityName: (state) => {
    return (form: Form, opportunityId: string) => {
      return (
        form.opportunities.find((x) => x.opportunityId === opportunityId)
          ?.name || null
      );
    };
  },
  hasExistingFormOpportunityProtocol: (state) => {
    return (
      form: Form,
      opportunityId: string,
      jobType: JobTypeEnum,
      protocolType: ProtocolTypeEnum
    ) => {
      return (
        Array.from(form.protocol[opportunityId] || []).findIndex((fop) => {
          return fop.jobType === jobType && fop.protocolType === protocolType;
        }) > -1
      );
    };
  },
};
