import { MutationTree } from "vuex";
import { State, FailedOrder, SuccessfulOrder } from "./state";
import { MutationTypes } from "./types";

// Mutation Types
export type Mutations<S = State> = {
  [MutationTypes.ORDER_SET_FAILED_ORDERS](
    state: S,
    payload: FailedOrder[]
  ): void;
  [MutationTypes.ORDER_SET_SUCCESSFUL_ORDERS](
    state: S,
    payload: SuccessfulOrder[]
  ): void;
  [MutationTypes.ORDER_SET_FAILED_ORDER](state: S, payload: FailedOrder): void;
  [MutationTypes.ORDER_SET_FAILED_ORDERS_COUNT](
    state: S,
    payload: number
  ): void;
  [MutationTypes.ORDER_SET_SUCCESSFUL_ORDERS_COUNT](
    state: S,
    payload: number
  ): void;
};

// define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.ORDER_SET_FAILED_ORDERS](
    state: State,
    payload: FailedOrder[]
  ) {
    state.failedOrders.splice(0, state.failedOrders.length);
    state.failedOrders.push(...payload);
  },
  [MutationTypes.ORDER_SET_SUCCESSFUL_ORDERS](
    state: State,
    payload: SuccessfulOrder[]
  ) {
    state.successfulOrders.splice(0, state.successfulOrders.length);
    state.successfulOrders.push(...payload);
  },
  [MutationTypes.ORDER_SET_FAILED_ORDER](state: State, payload: FailedOrder) {
    const existingFailedOrderIndex = state.failedOrders.findIndex(
      (x) => x.key === payload.key
    );
    if (existingFailedOrderIndex > -1) {
      state.failedOrders[existingFailedOrderIndex] = payload;
    } else {
      state.failedOrders.push(payload);
    }
  },
  [MutationTypes.ORDER_SET_FAILED_ORDERS_COUNT](state: State, payload: number) {
    state.failedOrdersCount = payload;
  },
  [MutationTypes.ORDER_SET_SUCCESSFUL_ORDERS_COUNT](
    state: State,
    payload: number
  ) {
    state.successfulOrdersCount = payload;
  },
};
