import { http } from "@/axios";
import {
  SuccessfulOrderCountResponse,
  SuccessfulOrdersResponse,
  TransmitOrderResponse,
} from "./successful-orders.interface";

export const fetchSuccessfulOrders = async (
  page: number,
  limit: number,
  sortBy?: string,
  sortOrder?: string
): Promise<SuccessfulOrdersResponse> => {
  const params = new URLSearchParams();
  params.append("page", page.toString());
  params.append("limit", limit.toString());
  sortBy && params.append("sortBy", sortBy);
  sortOrder && params.append("sortOrder", sortOrder);
  const { data } = await http.get<SuccessfulOrdersResponse>(
    `/order/successful?${params.toString()}`
  );
  return data;
};

export const fetchSuccessfulOrdersCount =
  async (): Promise<SuccessfulOrderCountResponse> => {
    const { data } = await http.get<SuccessfulOrderCountResponse>(
      "/order/successful/count"
    );
    return data;
  };

export const transmitOrder = async (
  id: string
): Promise<TransmitOrderResponse> => {
  const { data } = await http.post<TransmitOrderResponse>(
    `/order/transmit/${id}`
  );
  return data;
};
