import { http } from "@/axios";
import {
  DeleteFailedOrderResponse,
  FailedOrderResponse,
  FailedOrderCountResponse,
  FailedOrdersResponse,
  AnswersFilesResponse,
} from "./failed-orders.interface";

export const fetchFailedOrders = async (
  page: number,
  limit: number,
  countTotal = false
): Promise<FailedOrdersResponse> => {
  const params = new URLSearchParams();
  params.append("page", page.toString());
  params.append("limit", limit.toString());
  params.append("countTotal", countTotal.toString());
  const { data } = await http.get<FailedOrdersResponse>(
    `/order/failed?${params.toString()}`
  );
  return data;
};

export const fetchFailedOrder = async (
  key: string
): Promise<FailedOrderResponse> => {
  const { data } = await http.get<FailedOrderResponse>(`/order/failed/${key}`);
  return data;
};

export const fetchFailedOrdersCount =
  async (): Promise<FailedOrderCountResponse> => {
    const { data } = await http.get<FailedOrderCountResponse>(
      "/order/failed/count"
    );
    return data;
  };

export const fetchPreSignedAnswers = async (
  payload: any
): Promise<AnswersFilesResponse> => {
  const { data } = await http.post<AnswersFilesResponse>(
    "/order/answer",
    payload
  );
  return data;
};

export const saveFailedOrder = async (
  key: string,
  payload: any
): Promise<FailedOrderResponse> => {
  const { data } = await http.put<FailedOrderResponse>(
    `/order/failed/${key}`,
    payload
  );
  return data;
};

export const retryFailedOrder = async (
  key: string,
  payload: any
): Promise<FailedOrderResponse> => {
  const { data } = await http.put<FailedOrderResponse>(
    `/order/failed/${key}/retry`,
    payload
  );
  return data;
};

export const deleteFailedOrder = async (
  key: string
): Promise<DeleteFailedOrderResponse> => {
  const { data } = await http.delete<DeleteFailedOrderResponse>(
    `/order/failed/${key}`
  );
  return data;
};
