import { inject } from "vue";
import { locale } from "@/i18n";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en";
import "dayjs/locale/de";

dayjs.locale(locale);
dayjs.extend(localizedFormat);

// dayjs.Dayjs type does not seem to work
// https://github.com/iamkun/dayjs/issues/788
export function useDayJS(): any {
  const $dayjs: any | undefined = inject("$dayjs");

  if (!$dayjs) {
    throw new Error("Cannot inject dayjs");
  }

  return $dayjs;
}

export function useDayJSHelpers($dayjs: any) {
  function dayJSFormat1(input: string) {
    return $dayjs(input).format("LLL");
  }

  function dayJSDefaultNow() {
    return $dayjs().format();
  }

  function dayJSDefault(input: string) {
    return $dayjs(input).format();
  }

  return {
    dayJSFormat1,
    dayJSDefault,
    dayJSDefaultNow,
  };
}
