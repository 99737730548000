
import { defineComponent, inject, onMounted, nextTick } from "vue";

import { AxiosStatic } from "axios";
import { useRoute } from "vue-router";
import jwt_decode from "jwt-decode";

import Auth from "@/views/layouts/Auth.vue";
import NoAuth from "@/views/layouts/NoAuth.vue";
import { ActionTypes } from "@/store/auth.module/types";
import { useStore } from "@/store";

export default defineComponent({
  name: "App",
  components: {
    Auth,
    NoAuth,
  },
  setup(props) {
    const $axios: AxiosStatic | undefined = inject("$axios");
    const $route = useRoute();
    const $store = useStore();

    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);

    if ((params.get("resize") || "").toLowerCase() === "true") {
      let iframeResizer = document.createElement("script");
      iframeResizer.setAttribute(
        "src",
        "js/iframeResizer.contentWindow.min.js"
      );
      document.head.appendChild(iframeResizer);
    }

    onMounted(() => {
      const token = params.get("jwt") || localStorage.getItem("gid_bearer");

      if (token) {
        try {
          // try to parse the token to verify that it is a valid jwt
          jwt_decode(token);

          // set default auth header Bearer token for all requests
          if ($axios) {
            $axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          }

          // set gid_bearer in localStorage
          localStorage.setItem("gid_bearer", token);

          // set access token on vuex
          $store.dispatch(ActionTypes.AUTH_SET_ACCESS_TOKEN, token);
        } catch (e) {}
      }
    });

    return {
      store: $store,
    };
  },
});
