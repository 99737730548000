// declare state

export interface FailedOrder {
  publicURL: string;
  key: string;
  eTag?: string;
  lastModified: string | null;
  size: number | null;
  file?: Blob | null;
  content?: any;
}

export interface SuccessfulOrder {
  id: string;
  opportunityId: string;
  form: {
    id: number;
    name: string;
    apiName: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

export type State = {
  failedOrders: FailedOrder[];
  successfulOrders: SuccessfulOrder[];
  failedOrdersCount: number;
  successfulOrdersCount: number;
};

// set state
export const state: State = {
  failedOrders: [],
  successfulOrders: [],
  failedOrdersCount: 0,
  successfulOrdersCount: 0,
};
