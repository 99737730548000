import { ActionContext, ActionTree } from "vuex";
import { RootState } from "..";
import { Mutations } from "./mutations";
import {
  Flow,
  Form,
  FormSetCurrentFlow,
  FormSetFlow,
  FormSetFlowStep,
  FormSetFlowElements,
  FormMoveFlowElement,
  FormSetCurrentFlowElement,
  State,
  FormSetFlowElement,
  FormSetFlowElementRequired,
  FormSetFlowElementMetadata,
  FormSetFlowElementOptions,
  FormRemoveFlowElementOption,
  FormSetSettings,
  FormSetContent,
  FormSetFlowElementName,
  FormSetFlowElementMultiple,
  FormSetFlowElementAutoTemplateRender,
  FormRemoveFlowElement,
  FormSetFlowElementDependsOn,
  FormSetTitle,
  FormSetInternalTitle,
  FormSetActive,
  FormRemoveOpportunity,
  FormSetOpportunity,
  FormSetMapping,
  FormSetFormulaMapping,
  FormRemoveFormulaMapping,
  FormRemoveMapping,
  FormSetMetadata,
  FormSetProtocol,
  FormRemoveProtocol,
  FormSetProtocols,
  FormSetFlows,
  FormRemoveFlow,
  FormAddFlow,
  FormSetFlowElementOption,
} from "./state";
import { MutationTypes } from "./types";
import { ActionTypes } from "./types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// actions interface
export interface Actions {
  [ActionTypes.FORM_SET_FORM](
    { commit }: AugmentedActionContext,
    payload: Form
  ): void;
  [ActionTypes.FORM_ADD_FLOW](
    { commit }: AugmentedActionContext,
    payload: FormAddFlow
  ): void;
  [ActionTypes.FORM_REMOVE_FLOW](
    { commit }: AugmentedActionContext,
    payload: FormRemoveFlow
  ): void;
  [ActionTypes.FORM_SET_FLOWS](
    { commit }: AugmentedActionContext,
    payload: FormSetFlows
  ): void;
  [ActionTypes.FORM_SET_CURRENT_FLOW](
    { commit }: AugmentedActionContext,
    payload: FormSetCurrentFlow
  ): void;
  [ActionTypes.FORM_SET_FLOW](
    { commit }: AugmentedActionContext,
    payload: FormSetFlow
  ): void;
  [ActionTypes.FORM_SET_FLOW_STEP](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowStep
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENTS](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElements
  ): void;
  [ActionTypes.FORM_SET_CURRENT_FLOW_ELEMENT](
    { commit }: AugmentedActionContext,
    payload: FormSetCurrentFlowElement
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElement
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT_REQUIRED](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElementRequired
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT_MULTIPLE](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElementMultiple
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElementAutoTemplateRender
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT_NAME](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElementName
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT_METADATA](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElementMetadata
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT_OPTIONS](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElementOptions
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT_OPTION](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElementOption
  ): void;
  [ActionTypes.FORM_SET_FLOW_ELEMENT_DEPENDS_ON](
    { commit }: AugmentedActionContext,
    payload: FormSetFlowElementDependsOn
  ): void;
  [ActionTypes.FORM_REMOVE_FLOW_ELEMENT_OPTION](
    { commit }: AugmentedActionContext,
    payload: FormRemoveFlowElementOption
  ): void;
  [ActionTypes.FORM_REMOVE_FLOW_ELEMENT](
    { commit }: AugmentedActionContext,
    payload: FormRemoveFlowElement
  ): void;
  [ActionTypes.FORM_MOVE_FLOW_ELEMENT](
    { commit }: AugmentedActionContext,
    payload: FormMoveFlowElement
  ): void;
  [ActionTypes.FORM_SET_SETTINGS](
    { commit }: AugmentedActionContext,
    payload: FormSetSettings
  ): void;
  [ActionTypes.FORM_SET_CONTENT](
    { commit }: AugmentedActionContext,
    payload: FormSetContent
  ): void;
  [ActionTypes.FORM_SET_TITLE](
    { commit }: AugmentedActionContext,
    payload: FormSetTitle
  ): void;
  [ActionTypes.FORM_SET_INTERNAL_TITLE](
    { commit }: AugmentedActionContext,
    payload: FormSetInternalTitle
  ): void;
  [ActionTypes.FORM_SET_ACTIVE](
    { commit }: AugmentedActionContext,
    payload: FormSetActive
  ): void;
  [ActionTypes.FORM_SET_OPPORTUNITY](
    { commit }: AugmentedActionContext,
    payload: FormSetOpportunity
  ): void;
  [ActionTypes.FORM_REMOVE_OPPORTUNITY](
    { commit }: AugmentedActionContext,
    payload: FormRemoveOpportunity
  ): void;
  [ActionTypes.FORM_SET_MAPPING](
    { commit }: AugmentedActionContext,
    payload: FormSetMapping
  ): void;
  [ActionTypes.FORM_SET_FORMULA_MAPPING](
    { commit }: AugmentedActionContext,
    payload: FormSetFormulaMapping
  ): void;
  [ActionTypes.FORM_REMOVE_FORMULA_MAPPING](
    { commit }: AugmentedActionContext,
    payload: FormRemoveFormulaMapping
  ): void;
  [ActionTypes.FORM_REMOVE_MAPPING](
    { commit }: AugmentedActionContext,
    payload: FormRemoveMapping
  ): void;
  [ActionTypes.FORM_SET_METADATA](
    { commit }: AugmentedActionContext,
    payload: FormSetMetadata
  ): void;
  [ActionTypes.FORM_SET_PROTOCOL](
    { commit }: AugmentedActionContext,
    payload: FormSetProtocol
  ): void;
  [ActionTypes.FORM_REMOVE_PROTOCOL](
    { commit }: AugmentedActionContext,
    payload: FormRemoveProtocol
  ): void;
  [ActionTypes.FORM_SET_PROTOCOLS](
    { commit }: AugmentedActionContext,
    payload: FormSetProtocols
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.FORM_SET_FORM]({ commit }, payload: Form) {
    commit(MutationTypes.FORM_SET_FORM, payload);
  },
  [ActionTypes.FORM_ADD_FLOW]({ commit }, payload: FormAddFlow) {
    commit(MutationTypes.FORM_ADD_FLOW, payload);
  },
  [ActionTypes.FORM_REMOVE_FLOW]({ commit }, payload: FormRemoveFlow) {
    commit(MutationTypes.FORM_REMOVE_FLOW, payload);
  },
  [ActionTypes.FORM_SET_FLOWS]({ commit }, payload: FormSetFlows) {
    commit(MutationTypes.FORM_SET_FLOWS, payload);
  },
  [ActionTypes.FORM_SET_CURRENT_FLOW]({ commit }, payload: FormSetCurrentFlow) {
    commit(MutationTypes.FORM_SET_CURRENT_FLOW, payload);
  },
  [ActionTypes.FORM_SET_FLOW]({ commit }, payload: FormSetFlow) {
    commit(MutationTypes.FORM_SET_FLOW, payload);
  },
  [ActionTypes.FORM_SET_FLOW_STEP]({ commit }, payload: FormSetFlowStep) {
    commit(MutationTypes.FORM_SET_FLOW_STEP, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENTS](
    { commit },
    payload: FormSetFlowElements
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENTS, payload);
  },
  [ActionTypes.FORM_SET_CURRENT_FLOW_ELEMENT](
    { commit },
    payload: FormSetCurrentFlowElement
  ) {
    commit(MutationTypes.FORM_SET_CURRENT_FLOW_ELEMENT, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT]({ commit }, payload: FormSetFlowElement) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT_REQUIRED](
    { commit },
    payload: FormSetFlowElementRequired
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT_REQUIRED, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT_MULTIPLE](
    { commit },
    payload: FormSetFlowElementMultiple
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT_MULTIPLE, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER](
    { commit },
    payload: FormSetFlowElementAutoTemplateRender
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT_NAME](
    { commit },
    payload: FormSetFlowElementName
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT_NAME, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT_METADATA](
    { commit },
    payload: FormSetFlowElementMetadata
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT_METADATA, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT_OPTIONS](
    { commit },
    payload: FormSetFlowElementOptions
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT_OPTIONS, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT_OPTION](
    { commit },
    payload: FormSetFlowElementOption
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT_OPTION, payload);
  },
  [ActionTypes.FORM_SET_FLOW_ELEMENT_DEPENDS_ON](
    { commit },
    payload: FormSetFlowElementDependsOn
  ) {
    commit(MutationTypes.FORM_SET_FLOW_ELEMENT_DEPENDS_ON, payload);
  },
  [ActionTypes.FORM_REMOVE_FLOW_ELEMENT_OPTION](
    { commit },
    payload: FormRemoveFlowElementOption
  ) {
    commit(MutationTypes.FORM_REMOVE_FLOW_ELEMENT_OPTION, payload);
  },
  [ActionTypes.FORM_REMOVE_FLOW_ELEMENT](
    { commit },
    payload: FormRemoveFlowElement
  ) {
    commit(MutationTypes.FORM_REMOVE_FLOW_ELEMENT, payload);
  },
  [ActionTypes.FORM_MOVE_FLOW_ELEMENT](
    { commit },
    payload: FormMoveFlowElement
  ) {
    commit(MutationTypes.FORM_MOVE_FLOW_ELEMENT, payload);
  },
  [ActionTypes.FORM_SET_SETTINGS]({ commit }, payload: FormSetSettings) {
    commit(MutationTypes.FORM_SET_SETTINGS, payload);
  },
  [ActionTypes.FORM_SET_CONTENT]({ commit }, payload: FormSetContent) {
    commit(MutationTypes.FORM_SET_CONTENT, payload);
  },
  [ActionTypes.FORM_SET_TITLE]({ commit }, payload: FormSetTitle) {
    commit(MutationTypes.FORM_SET_TITLE, payload);
  },
  [ActionTypes.FORM_SET_INTERNAL_TITLE](
    { commit },
    payload: FormSetInternalTitle
  ) {
    commit(MutationTypes.FORM_SET_INTERNAL_TITLE, payload);
  },
  [ActionTypes.FORM_SET_ACTIVE]({ commit }, payload: FormSetActive) {
    commit(MutationTypes.FORM_SET_ACTIVE, payload);
  },
  [ActionTypes.FORM_SET_OPPORTUNITY]({ commit }, payload: FormSetOpportunity) {
    commit(MutationTypes.FORM_SET_OPPORTUNITY, payload);
  },
  [ActionTypes.FORM_REMOVE_OPPORTUNITY](
    { commit },
    payload: FormRemoveOpportunity
  ) {
    commit(MutationTypes.FORM_REMOVE_OPPORTUNITY, payload);
  },
  [ActionTypes.FORM_SET_MAPPING]({ commit }, payload: FormSetMapping) {
    commit(MutationTypes.FORM_SET_MAPPING, payload);
  },
  [ActionTypes.FORM_SET_FORMULA_MAPPING](
    { commit },
    payload: FormSetFormulaMapping
  ) {
    commit(MutationTypes.FORM_SET_FORMULA_MAPPING, payload);
  },
  [ActionTypes.FORM_REMOVE_FORMULA_MAPPING](
    { commit },
    payload: FormRemoveFormulaMapping
  ) {
    commit(MutationTypes.FORM_REMOVE_FORMULA_MAPPING, payload);
  },
  [ActionTypes.FORM_REMOVE_MAPPING]({ commit }, payload: FormRemoveMapping) {
    commit(MutationTypes.FORM_REMOVE_MAPPING, payload);
  },
  [ActionTypes.FORM_SET_METADATA]({ commit }, payload: FormSetMetadata) {
    commit(MutationTypes.FORM_SET_METADATA, payload);
  },
  [ActionTypes.FORM_SET_PROTOCOL]({ commit }, payload: FormSetProtocol) {
    commit(MutationTypes.FORM_SET_PROTOCOL, payload);
  },
  [ActionTypes.FORM_REMOVE_PROTOCOL]({ commit }, payload: FormRemoveProtocol) {
    commit(MutationTypes.FORM_REMOVE_PROTOCOL, payload);
  },
  [ActionTypes.FORM_SET_PROTOCOLS]({ commit }, payload: FormSetProtocols) {
    commit(MutationTypes.FORM_SET_PROTOCOLS, payload);
  },
};
