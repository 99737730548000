import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import { App, DirectiveBinding } from "vue";

export default {
  install: (app: App): void => {
    app.directive("scroll-lock", {
      mounted(el: HTMLElement, binding: DirectiveBinding<boolean>): void {
        if (binding.value) {
          disableBodyScroll(el);
        }
      },

      updated(el: HTMLElement, binding: DirectiveBinding<boolean>): void {
        if (binding.value) {
          disableBodyScroll(el);
        } else {
          enableBodyScroll(el);
        }
      },

      unmounted(el: HTMLElement): void {
        enableBodyScroll(el);
      },
    });
  },
};
