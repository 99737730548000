
import { defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { SuccessfulOrderCountResponse } from "@/views/successful-orders.interface";
import { fetchSuccessfulOrdersCount } from "@/views/successful-orders.service";
import { FailedOrderCountResponse } from "@/views/failed-orders.interface";
import { fetchFailedOrdersCount } from "@/views/failed-orders.service";
import { EventsEnum } from "@/views/websocket.interface";
import useWebSocket from "@/composables/websocket";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/order.module/types";

export default defineComponent({
  name: "Auth",
  setup(props) {
    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore();

    const activeIndex = ref("/home");
    const {
      websocket,
      send,
      registerListener,
      updateSuccessfulOrdersCount,
      updateFailedOrdersCount,
    } = useWebSocket();

    watch(
      () => $router.currentRoute.value.path,
      async (newPath) => {
        let newActiveIndex: string = newPath;
        const paramEntries = Object.entries($router.currentRoute.value.params);
        if (paramEntries.length) {
          let parameterlessUrl = newPath;

          for (const [key, value] of paramEntries) {
            parameterlessUrl = parameterlessUrl.replace(`/${value}`, "");
          }

          let baseParameterlessUrl: string = parameterlessUrl;
          const baseParameterlessUrlParts: string[] =
            parameterlessUrl.split("/");
          if (baseParameterlessUrlParts.length >= 2) {
            baseParameterlessUrl = `/${baseParameterlessUrlParts[1]}`;
          }
          newActiveIndex = baseParameterlessUrl;
        }

        activeIndex.value = newActiveIndex;
      }
    );

    // methods
    async function retrieveSuccessfulOrdersCount() {
      try {
        const response: SuccessfulOrderCountResponse =
          await fetchSuccessfulOrdersCount();
        $store.dispatch(
          ActionTypes.ORDER_SET_SUCCESSFUL_ORDERS_COUNT,
          response.count
        );
      } catch (err) {
        console.error(err);
      }
    }

    async function retrieveFailedOrdersCount() {
      try {
        const response: FailedOrderCountResponse =
          await fetchFailedOrdersCount();
        $store.dispatch(
          ActionTypes.ORDER_SET_FAILED_ORDERS_COUNT,
          response.count
        );
      } catch (err) {
        console.error(err);
      }
    }

    retrieveFailedOrdersCount();
    retrieveSuccessfulOrdersCount();

    registerListener(
      updateSuccessfulOrdersCount,
      EventsEnum.SUCCESSFUL_ORDERS_COUNT
    );
    registerListener(updateFailedOrdersCount, EventsEnum.FAILED_ORDERS_COUNT);

    return {
      activeIndex,
      store: $store,
    };
  },
});
