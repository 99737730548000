export enum ServicesEnum {
  ORDER_INTAKE_FORMS = "order-intake-forms",
  ORDER_INTAKE_FORMS_BACKOFFICE = "order-intake-forms-backoffice",
}

export enum EventsEnum {
  SUCCESSFUL_ORDERS_COUNT = "successful-orders-count",
  FAILED_ORDERS_COUNT = "failed-orders-count",
}

export interface MessagePayload {
  service: ServicesEnum;
  event: EventsEnum;
  value: string;
}
