import { v4 as uuidv4, validate } from "uuid";
import AES from "crypto-js/aes";
import CryptoJSEncUTF8 from "crypto-js/enc-utf8";
// https://www.npmjs.com/package/uuid
// https://developer.mozilla.org/en-US/docs/Web/API/Crypto (https only)

export default function useCrypto() {
  const generateUUID = () => uuidv4();

  const isValidUUID = (value: string) => validate(value);

  const generateString = (prefix: string = "gid") =>
    (prefix ? prefix : "") + (Math.random() + 1).toString(36).substring(2);

  const crypt = (salt: string, text: string) => {
    return AES.encrypt(text, salt).toString();
  };

  const decrypt = (salt: string, encrypted: string) => {
    return AES.decrypt(encrypted, salt).toString(CryptoJSEncUTF8);
  };

  return {
    generateUUID,
    isValidUUID,
    generateString,
    crypt,
    decrypt,
  };
}
