import { inject } from "vue";
import {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
} from "axios";
import { StatusCodes } from "http-status-codes";
import { ServicesEnum } from "./services.interface";

export function useAxios(): AxiosStatic {
  const $axios: AxiosStatic | undefined = inject("$axios");

  if (!$axios) {
    throw new Error("Cannot inject axios");
  }

  return $axios;
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Credentials": true,
  "X-Requested-With": "XMLHttpRequest",
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `gid_bearer` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token = localStorage.getItem("gid_bearer");

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    throw new Error(error as string | undefined);
  }
};

export class HttpRequest {
  private instance: AxiosInstance | null = null;
  private service: ServicesEnum;

  constructor(service: ServicesEnum) {
    this.service = service;
  }

  private resolveServiceBaseUrl() {
    switch (this.service) {
      case ServicesEnum.GID_CRUD:
        return "/data-api/";
      case ServicesEnum.ORDER_INTAKE_FORMS_API:
        return "/api/";
    }
  }

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const $axios: AxiosStatic = useAxios();

    const http: AxiosInstance = $axios.create({
      baseURL: this.resolveServiceBaseUrl(),
      headers,
      withCredentials: true,
    });

    http.interceptors.request.use(injectToken, (error) =>
      Promise.reject(error)
    );

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Handle global app errors
  private handleError(error: any) {
    const { status } = error;

    switch (status) {
      case StatusCodes.INTERNAL_SERVER_ERROR: {
        // Handle InternalServerError
        break;
      }
      case StatusCodes.FORBIDDEN: {
        // Handle Forbidden
        break;
      }
      case StatusCodes.UNAUTHORIZED: {
        // Handle Unauthorized
        break;
      }
      case StatusCodes.TOO_MANY_REQUESTS: {
        // Handle TooManyRequests
        break;
      }
    }

    return Promise.reject(error);
  }
}

export const http = new HttpRequest(ServicesEnum.ORDER_INTAKE_FORMS_API);
export const crudHttp = new HttpRequest(ServicesEnum.GID_CRUD);
