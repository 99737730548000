// declare state
export type State = {
  accessToken: string | null;
  salt: string;
};

// set state
export const state: State = {
  accessToken: null,
  salt: "&F)J@NcRfUjXn2r5u7x!A%D*G-KaPdSg",
};
