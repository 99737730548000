// mutations enums
export enum MutationTypes {
  FORM_SET_FORM = "FORM_SET_FORM",
  FORM_ADD_FLOW = "FORM_ADD_FLOW",
  FORM_REMOVE_FLOW = "FORM_REMOVE_FLOW",
  FORM_SET_CURRENT_FLOW = "FORM_SET_CURRENT_FLOW",
  FORM_SET_FLOW = "FORM_SET_FLOW",
  FORM_SET_FLOWS = "FORM_SET_FLOWS",
  FORM_SET_FLOW_STEP = "FORM_SET_FLOW_STEP",
  FORM_SET_FLOW_ELEMENTS = "FORM_SET_FLOW_ELEMENTS",
  FORM_SET_CURRENT_FLOW_ELEMENT = "FORM_SET_CURRENT_FLOW_ELEMENT",
  FORM_SET_FLOW_ELEMENT = "FORM_SET_FLOW_ELEMENT",
  FORM_SET_FLOW_ELEMENT_REQUIRED = "FORM_SET_FLOW_ELEMENT_REQUIRED",
  FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER = "FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER",
  FORM_SET_FLOW_ELEMENT_MULTIPLE = "FORM_SET_FLOW_ELEMENT_MULTIPLE",
  FORM_SET_FLOW_ELEMENT_NAME = "FORM_SET_FLOW_ELEMENT_NAME",
  FORM_SET_FLOW_ELEMENT_METADATA = "FORM_SET_FLOW_ELEMENT_METADATA",
  FORM_SET_FLOW_ELEMENT_OPTIONS = "FORM_SET_FLOW_ELEMENT_OPTIONS",
  FORM_SET_FLOW_ELEMENT_OPTION = "FORM_SET_FLOW_ELEMENT_OPTION",
  FORM_SET_FLOW_ELEMENT_DEPENDS_ON = "FORM_SET_FLOW_ELEMENT_DEPENDS_ON",
  FORM_REMOVE_FLOW_ELEMENT_OPTION = "FORM_REMOVE_FLOW_ELEMENT_OPTION",
  FORM_REMOVE_FLOW_ELEMENT = "FORM_REMOVE_FLOW_ELEMENT",
  FORM_MOVE_FLOW_ELEMENT = "FORM_MOVE_FLOW_ELEMENT",
  FORM_SET_SETTINGS = "FORM_SET_SETTINGS",
  FORM_SET_CONTENT = "FORM_SET_CONTENT",
  FORM_SET_TITLE = "FORM_SET_TITLE",
  FORM_SET_INTERNAL_TITLE = "FORM_SET_INTERNAL_TITLE",
  FORM_SET_ACTIVE = "FORM_SET_ACTIVE",
  FORM_SET_OPPORTUNITY = "FORM_SET_OPPORTUNITY",
  FORM_REMOVE_OPPORTUNITY = "FORM_REMOVE_OPPORTUNITY",
  FORM_SET_MAPPING = "FORM_SET_MAPPING",
  FORM_SET_FORMULA_MAPPING = "FORM_SET_FORMULA_MAPPING",
  FORM_REMOVE_FORMULA_MAPPING = "FORM_REMOVE_FORMULA_MAPPING",
  FORM_REMOVE_MAPPING = "FORM_REMOVE_MAPPING",
  FORM_SET_METADATA = "FORM_SET_METADATA",
  FORM_SET_PROTOCOL = "FORM_SET_PROTOCOL",
  FORM_REMOVE_PROTOCOL = "FORM_REMOVE_PROTOCOL",
  FORM_SET_PROTOCOLS = "FORM_SET_PROTOCOLS",
}

// action enums

export enum ActionTypes {
  FORM_SET_FORM = "FORM_SET_FORM",
  FORM_ADD_FLOW = "FORM_ADD_FLOW",
  FORM_REMOVE_FLOW = "FORM_REMOVE_FLOW",
  FORM_SET_CURRENT_FLOW = "FORM_SET_CURRENT_FLOW",
  FORM_SET_FLOW = "FORM_SET_FLOW",
  FORM_SET_FLOWS = "FORM_SET_FLOWS",
  FORM_SET_FLOW_STEP = "FORM_SET_FLOW_STEP",
  FORM_SET_FLOW_ELEMENTS = "FORM_SET_FLOW_ELEMENTS",
  FORM_SET_CURRENT_FLOW_ELEMENT = "FORM_SET_CURRENT_FLOW_ELEMENT",
  FORM_SET_FLOW_ELEMENT = "FORM_SET_FLOW_ELEMENT",
  FORM_SET_FLOW_ELEMENT_REQUIRED = "FORM_SET_FLOW_ELEMENT_REQUIRED",
  FORM_SET_FLOW_ELEMENT_MULTIPLE = "FORM_SET_FLOW_ELEMENT_MULTIPLE",
  FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER = "FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER",
  FORM_SET_FLOW_ELEMENT_NAME = "FORM_SET_FLOW_ELEMENT_NAME",
  FORM_SET_FLOW_ELEMENT_METADATA = "FORM_SET_FLOW_ELEMENT_METADATA",
  FORM_SET_FLOW_ELEMENT_OPTIONS = "FORM_SET_FLOW_ELEMENT_OPTIONS",
  FORM_SET_FLOW_ELEMENT_OPTION = "FORM_SET_FLOW_ELEMENT_OPTION",
  FORM_SET_FLOW_ELEMENT_DEPENDS_ON = "FORM_SET_FLOW_ELEMENT_DEPENDS_ON",
  FORM_REMOVE_FLOW_ELEMENT_OPTION = "FORM_REMOVE_FLOW_ELEMENT_OPTION",
  FORM_REMOVE_FLOW_ELEMENT = "FORM_REMOVE_FLOW_ELEMENT",
  FORM_MOVE_FLOW_ELEMENT = "FORM_MOVE_FLOW_ELEMENT",
  FORM_SET_SETTINGS = "FORM_SET_SETTINGS",
  FORM_SET_CONTENT = "FORM_SET_CONTENT",
  FORM_SET_TITLE = "FORM_SET_TITLE",
  FORM_SET_INTERNAL_TITLE = "FORM_SET_INTERNAL_TITLE",
  FORM_SET_ACTIVE = "FORM_SET_ACTIVE",
  FORM_SET_OPPORTUNITY = "FORM_SET_OPPORTUNITY",
  FORM_REMOVE_OPPORTUNITY = "FORM_REMOVE_OPPORTUNITY",
  FORM_SET_MAPPING = "FORM_SET_MAPPING",
  FORM_SET_FORMULA_MAPPING = "FORM_SET_FORMULA_MAPPING",
  FORM_REMOVE_FORMULA_MAPPING = "FORM_REMOVE_FORMULA_MAPPING",
  FORM_REMOVE_MAPPING = "FORM_REMOVE_MAPPING",
  FORM_SET_METADATA = "FORM_SET_METADATA",
  FORM_SET_PROTOCOL = "FORM_SET_PROTOCOL",
  FORM_REMOVE_PROTOCOL = "FORM_REMOVE_PROTOCOL",
  FORM_SET_PROTOCOLS = "FORM_SET_PROTOCOLS",
}
