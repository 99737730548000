import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authenticationMiddleware } from "./auth.middleware";
const Home = () => import("../views/Home.vue");
const NoAuth = () => import("../views/layouts/NoAuth.vue");
const FailedOrders = () => import("@/views/FailedOrders.vue");
const SuccessfulOrders = () => import("@/views/SuccessfulOrders.vue");
const FailedOrder = () => import("@/views/FailedOrder.vue");
const DragAndDropPlayground = () => import("@/views/DragAndDropPlayground.vue");
const Form = () => import("@/views/Form.vue");
const Forms = () => import("@/views/Forms.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    component: Home,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/admin",
    name: "admin",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: NoAuth,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/failed-orders",
    name: "failed-orders",
    component: FailedOrders,
  },
  {
    path: "/successful-orders",
    name: "successful-orders",
    component: SuccessfulOrders,
  },
  {
    path: "/failed-orders/:id",
    name: "failed-order",
    component: FailedOrder,
    props: true,
  },
  {
    path: "/drag-n-drop/playground",
    name: "drag-n-drop-playground",
    component: DragAndDropPlayground,
  },
  {
    path: "/form/edit/:id",
    name: "form-edit",
    component: Form,
    props: true,
  },
  {
    path: "/forms",
    name: "forms",
    component: Forms,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authenticationMiddleware);

export default router;
