import { GetterTree } from "vuex";
import { RootState } from "..";
import { State } from "./state";

// Getters types
export type Getters = {
  accessToken(state: State): string | null;
  isAuthenticated(state: State): boolean;
  salt(state: State): string;
};

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  accessToken: (state) => {
    return state.accessToken;
  },
  isAuthenticated: (state) => {
    return !!state.accessToken;
  },
  salt: (state) => {
    return state.salt;
  },
};
