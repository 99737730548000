import ReconnectingWebSocket from "reconnecting-websocket";
import { ref } from "vue";
import { makeLink, ProtocolsEnum } from "@gid/api-client";
import {
  EventsEnum,
  MessagePayload,
  ServicesEnum,
} from "@/views/websocket.interface";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/order.module/types";
import { getEnv } from "@/utils";

export default function useWebSocket() {
  const $store = useStore();
  const wsEnvURL = getEnv("VUE_APP_WS_API_ORDER_INTAKE_URL");
  const host = wsEnvURL
    ? wsEnvURL
    : makeLink(
        "order-intake-forms",
        "/api",
        window.location.protocol.includes("https")
          ? ProtocolsEnum.WSS
          : ProtocolsEnum.WS
      );

  const websocket = ref<ReconnectingWebSocket>(
    new ReconnectingWebSocket(`${host}`)
  );

  const LISTENER_ON_MESSAGE = "message";

  const send = (payload: Object) => {
    if (websocket.value.readyState !== WebSocket.OPEN) {
      console.error("Could not send", payload);
      return;
    }
    websocket.value.send(JSON.stringify(payload));
  };

  const executeFunctionOnEvent = (
    fn: Function,
    event: EventsEnum,
    service: ServicesEnum = ServicesEnum.ORDER_INTAKE_FORMS_BACKOFFICE
  ) => {
    return function curriedFn(me: MessageEvent) {
      const messagePayload: MessagePayload = JSON.parse(
        me.data
      ) as MessagePayload;

      if (messagePayload.service === service) {
        if (messagePayload.event === event) {
          fn(messagePayload);
          return;
        }
      }
    };
  };

  const registerListener = function (
    fn: Function,
    event: EventsEnum,
    service: ServicesEnum = ServicesEnum.ORDER_INTAKE_FORMS_BACKOFFICE
  ) {
    websocket.value.addEventListener(
      LISTENER_ON_MESSAGE,
      executeFunctionOnEvent(fn, event, service)
    );
  };

  const updateSuccessfulOrdersCount = function (
    messagePayload: MessagePayload
  ) {
    $store.dispatch(
      ActionTypes.ORDER_SET_SUCCESSFUL_ORDERS_COUNT,
      Number(messagePayload.value)
    );
  };

  const updateFailedOrdersCount = function (messagePayload: MessagePayload) {
    $store.dispatch(
      ActionTypes.ORDER_SET_FAILED_ORDERS_COUNT,
      Number(messagePayload.value)
    );
  };

  return {
    LISTENER_ON_MESSAGE,
    websocket,
    send,
    executeFunctionOnEvent,
    registerListener,
    updateSuccessfulOrdersCount,
    updateFailedOrdersCount,
  };
}
