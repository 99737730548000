import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import { store } from "./store";
import axios from "axios";
import dayjs from "dayjs";
import { useDayJSHelpers } from "@/dayjs";
import ElementPlus from "element-plus";
import "./element-variables.scss";
import CountryFlag from "vue-country-flag-next";
import BodyScrollLock from "./plugins/bodyscrolllock";

const app = createApp(App);

const { dayJSFormat1 } = useDayJSHelpers(dayjs);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$dayJSFormat1 = dayJSFormat1;

app.provide("$axios", axios);
app.provide("$dayjs", dayjs);

app.use(store);
app.use(router);
app.use(i18n);
app.use(ElementPlus);
app.use(CountryFlag);
app.use(BodyScrollLock);
app.mount("#app");

export default app;
