import { createI18n } from "vue-i18n";
import messages from "./messages";

export const locale = "en";

const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: locale,
  messages,
});

export default i18n;
