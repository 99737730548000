import { ActionContext, ActionTree } from "vuex";
import { RootState } from "..";
import { Mutations } from "./mutations";
import { State, FailedOrder, SuccessfulOrder } from "./state";
import { MutationTypes } from "./types";
import { ActionTypes } from "./types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// actions interface
export interface Actions {
  [ActionTypes.ORDER_SET_FAILED_ORDERS](
    { commit }: AugmentedActionContext,
    payload: FailedOrder[]
  ): void;
  [ActionTypes.ORDER_SET_SUCCESSFUL_ORDERS](
    { commit }: AugmentedActionContext,
    payload: SuccessfulOrder[]
  ): void;
  [ActionTypes.ORDER_SET_FAILED_ORDER](
    { commit }: AugmentedActionContext,
    payload: FailedOrder
  ): void;
  [ActionTypes.ORDER_SET_FAILED_ORDERS_COUNT](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [ActionTypes.ORDER_SET_SUCCESSFUL_ORDERS_COUNT](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.ORDER_SET_FAILED_ORDERS]({ commit }, payload: FailedOrder[]) {
    commit(MutationTypes.ORDER_SET_FAILED_ORDERS, payload);
  },
  [ActionTypes.ORDER_SET_SUCCESSFUL_ORDERS](
    { commit },
    payload: SuccessfulOrder[]
  ) {
    commit(MutationTypes.ORDER_SET_SUCCESSFUL_ORDERS, payload);
  },
  [ActionTypes.ORDER_SET_FAILED_ORDER]({ commit }, payload: FailedOrder) {
    commit(MutationTypes.ORDER_SET_FAILED_ORDER, payload);
  },
  [ActionTypes.ORDER_SET_FAILED_ORDERS_COUNT]({ commit }, payload: number) {
    commit(MutationTypes.ORDER_SET_FAILED_ORDERS_COUNT, payload);
  },
  [ActionTypes.ORDER_SET_SUCCESSFUL_ORDERS_COUNT]({ commit }, payload: number) {
    commit(MutationTypes.ORDER_SET_SUCCESSFUL_ORDERS_COUNT, payload);
  },
};
