import { MutationTree } from "vuex";
import { State } from "./state";
import { MutationTypes } from "./types";

// Mutation Types
export type Mutations<S = State> = {
  [MutationTypes.AUTH_SET_ACCESS_TOKEN](state: S, payload: string | null): void;
};

// define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.AUTH_SET_ACCESS_TOKEN](state: State, payload: string | null) {
    state.accessToken = payload;
  },
};
