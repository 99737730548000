import { GetterTree } from "vuex";
import { RootState } from "..";
import { State, FailedOrder, SuccessfulOrder } from "./state";
import dayjs from "dayjs";

// Getters types
export type Getters = {
  failedOrders(state: State): FailedOrder[];
  successfulOrders(state: State): SuccessfulOrder[];
  failedOrderByKey(state: State): (key: string) => FailedOrder | undefined;
  failedOrdersCount(state: State): number;
  successfulOrdersCount(state: State): number;
};

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  failedOrders: (state) => {
    return state.failedOrders.sort(
      (a, b) => dayjs(b.lastModified).unix() - dayjs(a.lastModified).unix()
    );
  },
  successfulOrders: (state) => {
    return state.successfulOrders;
  },
  failedOrderByKey: (state) => {
    return (key: string) => state.failedOrders.find((x) => x.key === key);
  },
  failedOrdersCount: (state) => {
    return state.failedOrdersCount;
  },
  successfulOrdersCount: (state) => {
    return state.successfulOrdersCount;
  },
};
