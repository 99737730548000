export interface CustomerData {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  countryCode?: string;
  city: string;
  postalCode: string;
  address: string;
}

export enum CountryCodeEnum {
  GERMANY = "DE",
  FRANCE = "FR",
  BELGIUM = "BE",
  AUSTRIA = "AT",
  USA = "US",
  SWITZERLAND = "CH",
  SWEDEN = "SE",
  SLOVENIA = "SI",
  NETHERLANDS = "NL",
  ITALY = "IT",
  LUXEMBOURG = "LU",
  TURKEY = "TR",
  DENMARK = "DK",
  UNITED_KINGDOM = "GB",
  POLAND = "PL",
  CZECH_REPUBLIC = "CZ",
}
