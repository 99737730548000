import { ActionContext, ActionTree } from "vuex";
import { RootState } from "..";
import { Mutations } from "./mutations";
import { State } from "./state";
import { MutationTypes } from "./types";
import { ActionTypes } from "./types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// actions interface
export interface Actions {
  [ActionTypes.AUTH_SET_ACCESS_TOKEN](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.AUTH_SET_ACCESS_TOKEN]({ commit }, payload: string | null) {
    commit(MutationTypes.AUTH_SET_ACCESS_TOKEN, payload);
  },
};
