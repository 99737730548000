import { ElementTypes } from "@/views/form-left-sidebar.interface";
import { FormImportSearchHints } from "@/views/search.interface";
import {
  JobTypeEnum,
  ProtocolTypeEnum,
  FormStrategyEnum,
  FormOrderModificationThroughEnum,
  FormOrderModificationCategoryEnum,
  JobStatusEnum,
  IdentificationStrategiesEnum,
  CalculateOnStepsEnum,
  OrderFormFormulaMapping,
  OrderFormQuestionPopulatedJobFieldEnum,
} from "@gid/models";

// declare state

export interface FlowElement {
  name: string;
  required: boolean;
  multiple: boolean;
  dependsOn: string;
  metadata: {
    [key: string]: any;
  } | null;
  translation: {
    [locale: string]: FlowElementFields;
  };
  sort: string;
  type: ElementTypes;
  active: boolean;
  id: string;
  autoTemplateRender: boolean;
}

export interface FlowElementFields {
  title: string;
  description: string | null;
  defaultAnswer: string | null;
  populatedJob?: OrderFormQuestionPopulatedJobFieldEnum | null;
  options?: any[];
  id?: string;
}

export interface Flow {
  translation: {
    [locale: string]: FlowFields;
  };
  step: string;
  formUUID?: string;
  elements: FlowElement[];
  id: string;
  formFlowId?: string;
}

export interface FlowFields {
  title: string;
  description: string;
  id?: string;
}

export interface FormTranslation {
  title: string;
  internalTitle: string;
  description: string;
  content: object;
  id?: string;
}

export interface FormMetadata {
  export?: {
    hostname: string;
  };
}

export interface Form {
  locale: string;
  name: string;
  translation: {
    [locale: string]: FormTranslation;
  };
  flows: Flow[];
  currentFlowUUID?: string | null;
  currentElementUUID?: string | null;
  settings: FormSettings;
  id: string;
  active: boolean;
  search?: {
    hints?: FormImportSearchHints;
  };
  opportunities: FormOpportunity[];
  mapping: {
    default: FormMapping;
    [opportunityId: string]: FormMapping;
  };
  protocol: {
    [opportunityId: string]: FormProtocol[];
  };
  metadata?: FormMetadata | null;
}

export interface FormProtocol {
  id: string;
  opportunityId: string;
  jobType: JobTypeEnum;
  protocolType: ProtocolTypeEnum;
  locale: string;
  template: string;
  alreadyVisited?: boolean;
}

export interface FormOpportunity {
  id: string;
  name: string;
  opportunityId: string;
  jobTypes: JobTypeEnum[];
}

export interface FormSetOpportunity {
  formUUID: string;
  opportunity: FormOpportunity;
}

export interface FormRemoveOpportunity {
  formUUID: string;
  formOpportunityUUID: string;
}

export interface FormMapping {
  opportunityId: string;
  entries: FormFormulaMapping[];
}

export interface FormFormulaMapping
  extends Omit<
    OrderFormFormulaMapping,
    | "createdAt"
    | "updatedAt"
    | "form"
    | "formId"
    | "opportunity"
    | "opportunityId"
  > {
  alreadyVisited?: boolean;
}

export interface FormSettings {
  identificationStrategy: IdentificationStrategiesEnum | null;
  hasProgress: boolean;
  hasPrice: boolean;
  hasPriceAffiliate: boolean;
  hasSummary: boolean;
  priceEstimateFrom: number | null;
  calculateOnSteps: CalculateOnStepsEnum[];
  theme: string;
  orderStrategyMode: FormStrategyEnum;
  orderStrategyCreation?: OrderStrategyCreation;
  orderStrategyModification?: OrderStrategyModification;
  delegatedFormId: string | null;
  id?: string;
}

export interface OrderStrategyModification {
  through: FormOrderModificationThroughEnum;
  newStatus: JobStatusEnum;
  category: FormOrderModificationCategoryEnum;
  cancellationReasonName: string | null;
  id?: string;
}

export interface OrderStrategyCreation {
  laxValidation: boolean;
  id?: string;
}

export interface FormSetSettings {
  formUUID: string;
  settings: FormSettings;
}

export interface FormSetContent {
  formUUID: string;
  content: object;
}

export interface FormSetTitle {
  formUUID: string;
  title: string;
}

export interface FormSetInternalTitle {
  formUUID: string;
  internalTitle: string;
}

export interface FormSetActive {
  formUUID: string;
  active: boolean;
}

export interface FormSetCurrentFlow {
  formUUID: string;
  flowUUID: string | null;
}

export interface FormSetFlow {
  formUUID: string;
  flow: Flow;
}

export interface FormSetFlowStep {
  formUUID: string;
  flowUUID: string;
  step: string;
}

export interface FormSetFlows {
  formUUID: string;
  flows: Flow[];
}

export interface FormRemoveFlow {
  formUUID: string;
  flowUUID: string;
}

export interface FormAddFlow {
  formUUID: string;
  flow: Flow;
}

export interface FormSetFlowElements {
  formUUID: string;
  flowUUID: string;
  elements: FlowElement[];
}

export interface FormSetFlowElement {
  formUUID: string;
  flowUUID: string;
  element: FlowElement;
}

export interface FormSetCurrentFlowElement {
  formUUID: string;
  elementUUID: string | null;
}

export interface FormSetFlowElementRequired {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  required: string;
}

export interface FormSetFlowElementMultiple {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  multiple: string;
}

export interface FormSetFlowElementAutoTemplateRender {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  autoTemplateRender: string;
}

export interface FormSetFlowElementName {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  name: string;
}

export interface FormSetFlowElementMetadata {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  metadata: {
    [key: string]: any;
  } | null;
}

export interface FormSetFlowElementOptions {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  options: any[];
}

export interface FormSetFlowElementOption {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  option: any;
}

export interface FormSetFlowElementDependsOn {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  dependsOn: string;
}

export interface FormRemoveFlowElementOption {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
  option: string;
}

export interface FormRemoveFlowElement {
  formUUID: string;
  flowUUID: string;
  elementUUID: string;
}

export interface FormMoveFlowElement {
  formUUID: string;
  currFlowUUID: string;
  newFlowUUID: string;
  elementUUID: string;
}

export interface FormSetMapping {
  formUUID: string;
  mapping: FormMapping;
}

export interface FormSetFormulaMapping {
  formUUID: string;
  opportunityId: string;
  entry: FormFormulaMapping;
}

export interface FormRemoveFormulaMapping {
  formUUID: string;
  opportunityId: string;
  formulaMappingUUID: string;
}

export interface FormRemoveMapping {
  formUUID: string;
  opportunityId: string;
}

export interface FormSetMetadata {
  formUUID: string;
  metadata: FormMetadata | null;
}

export interface FormSetProtocol {
  formUUID: string;
  protocol: FormProtocol;
}

export interface FormRemoveProtocol {
  formUUID: string;
  opportunityId: string;
  protocolUUID: string;
}

export interface FormSetProtocols {
  formUUID: string;
  protocols: FormProtocol[];
}

export type State = {
  [uuid: string]: Form;
};

// set state
export const state: State = {};
