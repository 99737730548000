// mutations enums
export enum MutationTypes {
  ORDER_SET_FAILED_ORDERS = "ORDER_SET_FAILED_ORDERS",
  ORDER_SET_SUCCESSFUL_ORDERS = "ORDER_SET_SUCCESSFUL_ORDERS",
  ORDER_SET_FAILED_ORDER = "ORDER_SET_FAILED_ORDER",
  ORDER_SET_FAILED_ORDERS_COUNT = "ORDER_SET_FAILED_ORDERS_COUNT",
  ORDER_SET_SUCCESSFUL_ORDERS_COUNT = "ORDER_SET_SUCCESSFUL_ORDERS_COUNT",
}

// action enums
export enum ActionTypes {
  ORDER_SET_FAILED_ORDERS = "ORDER_SET_FAILED_ORDERS",
  ORDER_SET_SUCCESSFUL_ORDERS = "ORDER_SET_SUCCESSFUL_ORDERS",
  ORDER_SET_FAILED_ORDER = "ORDER_SET_FAILED_ORDER",
  ORDER_SET_FAILED_ORDERS_COUNT = "ORDER_SET_FAILED_ORDERS_COUNT",
  ORDER_SET_SUCCESSFUL_ORDERS_COUNT = "ORDER_SET_SUCCESSFUL_ORDERS_COUNT",
}
