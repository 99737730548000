import { NavigationGuardWithThis } from "vue-router";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { ActionTypes } from "@/store/auth.module/types";
import { useStore } from "@/store";

const store = useStore();

export const authenticationMiddleware: NavigationGuardWithThis<undefined> = (
  to,
  from,
  next
) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";

  if (to.meta && to.meta.isPublic) {
    next();
    return;
  }

  const token =
    (to.query.gid_bearer as string) || localStorage.getItem("gid_bearer");

  if (token) {
    try {
      // try parse the token to verify that it is a valid jwt
      let parsed: unknown = jwt_decode(token);

      // verify that the token is not expired
      const now = new Date().getTime();
      if ((parsed as any).exp * 1000 > now) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        store.dispatch(ActionTypes.AUTH_SET_ACCESS_TOKEN, token);
        next();
        return;
      } else {
        localStorage.removeItem("gid_bearer");
      }
    } catch (e) {}
  }

  store.dispatch(ActionTypes.AUTH_SET_ACCESS_TOKEN, null);
  next({ name: "login" });
};
