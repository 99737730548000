import {
  createStore,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  createLogger,
} from "vuex";

import {
  AuthModule,
  Store as AuthStore,
  State as AuthState,
} from "./auth.module/";
import {
  OrderModule,
  Store as OrderStore,
  State as OrderState,
} from "./order.module/";
import {
  FormModule,
  Store as FormStore,
  State as FormState,
} from "./form.module/";

export type RootState = {
  auth: AuthState;
  order: OrderState;
  form: FormState;
};

export type Store = AuthStore<Pick<RootState, "auth">> &
  OrderStore<Pick<RootState, "order">> &
  FormStore<Pick<RootState, "form">>;

export const store = createStore({
  modules: {
    auth: AuthModule,
    order: OrderModule,
    form: FormModule,
  },
  plugins: [createLogger()],
});

export function useStore(): Store {
  return store as Store;
}
