import { CountryCodeEnum } from "./views/customer.interface";

export function getEnv(name) {
  return window?.env?.[name] ?? process?.env?.[name];
}
export function Agent() {}

export function countryToCountryCode(value) {
  if (!value) {
    return null;
  }
  if (value.toLowerCase() === "deutschland") {
    return CountryCodeEnum.GERMANY;
  } else if (value.toLowerCase() === "österreich") {
    return CountryCodeEnum.AUSTRIA;
  } else if (Object.keys(CountryCodeEnum).includes(value.toUpperCase())) {
    return CountryCodeEnum[value.toUpperCase()];
  }
  return CountryCodeEnum.GERMANY;
}
